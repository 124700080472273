<template>
	<div class="com">
		<el-carousel :interval="5000" arrow="always" class="carousel">
			<el-carousel-item v-for="item in index_benner" :key="item.img">
				<img style="width:100%;height:100%;cursor: pointer;"
					@click="toLink(item.link)" v-lazy="item.img+'?x-oss-process=image/format,jpg'" />
			</el-carousel-item>
		</el-carousel>
		<div class="About-Us_and_Course">
			<div class="About-Us">
				<img class="About-Us_bgimg" src="../../assets/img/About-Us@2x.png" alt srcset />

				<div class="About-Us_concent">
					<div class="About-Us_title">
						<div class="title_icon"></div>
						<div class="title_title1">公司简介</div>
						<div class="title_title2">About Us</div>
					</div>

					<div class="About-Us_text">
						<div>
							亿事达集团成立于2009年，是武汉本土领先的城市配套商业服务商，现有行业专才500余人。在商业资产运作领域拥有丰富的经验和雄厚的实力，11年时间已经成为一家以核心人才与全能托管为核心价值的专业机构，未来，亿事达将进一步深耕武汉，辐射华中，并逐步走向全国。
						</div>
						<div class="About-Us_text_text">
							亿事达集团具备资深的商业投资、顾问咨询、招商代理、项目托管、整体租赁、资产并购及商业地产合作开发实操经验。目前运营管理的商业面积超过80万方，业务涉及大卖场、购物中心、情景街区、主题百货、专业市场、旅游地产等。为商业赋能，为资产增值是我们长期的坚持的使命，亿事达集团亦将为成为中国最优质的商业地产服务商而步履不停。
						</div>
					</div>

					<div class="About-Us_img">
						<img src="../../assets/img/Clipboard@2x.png" alt />
						<div class="About-Us_img_icon1"></div>
						<div class="About-Us_img_icon2"></div>
					</div>
				</div>
			</div>

			<div class="Course">
				<svg class="Course_bgimg" xmlns="http://www.w3.org/2000/svg" width="1200.607" height="374.67"
					viewBox="0 0 1200.607 374.67">
					<g id="经历线" transform="translate(-361.757 -1269.56)">
						<g id="经历线-2" data-name="经历线" class="cls-1" transform="translate(362.904 1270.755)">
							<path id="路径_115" data-name="路径 115" class="cls-2 yy"
								d="M361.9,1582.752c98.927-30.375,135.6-18.108,205.337-82.821s241.449-14.527,395.686-87.165,243.848-19.57,385.424-75.279,170.029-27.345,212.552-127.733"
								transform="translate(-361.904 -1209.755)" />
							<circle id="椭圆_12" data-name="椭圆 12" class="cls-3 cc_animation0" cx="4" cy="4" r="4"
								transform="translate(14.096 364.245)" />
							<circle id="椭圆_11" data-name="椭圆 11" class="cls-3 cc_animation1" cx="4" cy="4" r="4"
								transform="translate(87.096 346.245)" />
							<circle id="椭圆_3" data-name="椭圆 3" class="cls-3 cc_animation2" cx="4" cy="4" r="4"
								transform="translate(134.096 332.245)" />
							<circle id="椭圆_42" data-name="椭圆 42" class="cls-3 cc_animation3" cx="4" cy="4" r="4"
								transform="translate(218.096 274.245)" />
							<circle id="椭圆_10" data-name="椭圆 10" class="cls-3 cc_animation4" cx="4" cy="4" r="4"
								transform="translate(268.624 256.245)" />
							<circle id="椭圆_9" data-name="椭圆 9" class="cls-3 cc_animation5" cx="4" cy="4" r="4"
								transform="translate(324.525 248.245)" />
							<circle id="椭圆_8" data-name="椭圆 8" class="cls-3 cc_animation6" cx="4" cy="4" r="4"
								transform="translate(409.376 244.245)" />
							<circle id="椭圆_7" data-name="椭圆 7" class="cls-3 cc_animation7" cx="4" cy="4" r="4"
								transform="translate(479.253 233.245)" />
							<circle id="椭圆_6" data-name="椭圆 6" class="cls-3 cc_animation8" cx="4" cy="4" r="4"
								transform="translate(549.004 218.245)" />
							<circle id="椭圆_5" data-name="椭圆 5" class="cls-3 cc_animation9" cx="4" cy="4" r="4"
								transform="translate(619.007 190.245)" />
							<circle id="椭圆_4" data-name="椭圆 4" class="cls-3 cc_animation10" cx="4" cy="4" r="4"
								transform="translate(700.863 165.245)" />
							<circle id="椭圆_32" data-name="椭圆 32" class="cls-3 cc_animation11" cx="4" cy="4" r="4"
								transform="translate(767.745 157.245)" />
							<circle id="椭圆_33" data-name="椭圆 33" class="cls-3 cc_animation12" cx="4" cy="4" r="4"
								transform="translate(826.641 153.245)" />
							<circle id="椭圆_34" data-name="椭圆 34" class="cls-3 cc_animation13" cx="4" cy="4" r="4"
								transform="translate(924.469 142.245)" />
							<circle id="椭圆_35" data-name="椭圆 35" class="cls-3 cc_animation14" cx="4" cy="4" r="4"
								transform="translate(984 123.245)" />
							<circle id="椭圆_36" data-name="椭圆 36" class="cls-3 cc_animation15" cx="4" cy="4" r="4"
								transform="translate(1040 103.245)" />
							<circle id="椭圆_37" data-name="椭圆 37" class="cls-3 cc_animation16" cx="4" cy="4" r="4"
								transform="translate(1100 85.245)" />
							<circle id="椭圆_38" data-name="椭圆 38" class="cls-3 cc_animation17" cx="4" cy="4" r="4"
								transform="translate(1160 54.245)" />
						</g>
						<g id="经历线-3" data-name="经历线" transform="translate(361.904 1269.755)">
							<path id="路径_115-2" data-name="路径 115" class="cls-2"
								d="M361.9,1582.752c98.927-30.375,135.6-18.108,205.337-82.821s241.449-14.527,395.686-87.165,243.848-19.57,385.424-75.279,170.029-27.345,212.552-127.733"
								transform="translate(-361.904 -1209.755)" />
							<circle id="椭圆_12-2" data-name="椭圆 12" class="cls-3 cc_animation0" cx="4" cy="4" r="4"
								transform="translate(14.096 364.245)" />
							<circle id="椭圆_11-2" data-name="椭圆 11" class="cls-3 cc_animation1" cx="4" cy="4" r="4"
								transform="translate(87.096 346.245)" />
							<circle id="椭圆_3-2" data-name="椭圆 3" class="cls-3 cc_animation2" cx="4" cy="4" r="4"
								transform="translate(134.096 332.245)" />
							<circle id="椭圆_42-2" data-name="椭圆 42" class="cls-3 cc_animation3" cx="4" cy="4" r="4"
								transform="translate(218.096 274.245)" />
							<circle id="椭圆_10-2" data-name="椭圆 10" class="cls-3 cc_animation4" cx="4" cy="4" r="4"
								transform="translate(268.624 256.245)" />
							<circle id="椭圆_9-2" data-name="椭圆 9" class="cls-3 cc_animation5" cx="4" cy="4" r="4"
								transform="translate(324.525 248.245)" />
							<circle id="椭圆_8-2" data-name="椭圆 8" class="cls-3 cc_animation6" cx="4" cy="4" r="4"
								transform="translate(409.376 244.245)" />
							<circle id="椭圆_7-2" data-name="椭圆 7" class="cls-3 cc_animation7" cx="4" cy="4" r="4"
								transform="translate(479.253 233.245)" />
							<circle id="椭圆_6-2" data-name="椭圆 6" class="cls-3 cc_animation8" cx="4" cy="4" r="4"
								transform="translate(549.004 218.245)" />
							<circle id="椭圆_5-2" data-name="椭圆 5" class="cls-3 cc_animation9" cx="4" cy="4" r="4"
								transform="translate(619.007 190.245)" />
							<circle id="椭圆_4-2" data-name="椭圆 4" class="cls-3 cc_animation10" cx="4" cy="4" r="4"
								transform="translate(700.863 165.245)" />
							<circle id="椭圆_32-2" data-name="椭圆 32" class="cls-3 cc_animation11" cx="4" cy="4" r="4"
								transform="translate(767.745 157.245)" />
							<circle id="椭圆_33-2" data-name="椭圆 33" class="cls-3 cc_animation12" cx="4" cy="4" r="4"
								transform="translate(826.641 153.245)" />
							<circle id="椭圆_34-2" data-name="椭圆 34" class="cls-3  cc_animation13" cx="4" cy="4" r="4"
								transform="translate(924.469 142.245)" />
							<circle id="椭圆_35-2" data-name="椭圆 35" class="cls-3 cc_animation14" cx="4" cy="4" r="4"
								transform="translate(984 123.245)" />
							<circle id="椭圆_36-2" data-name="椭圆 36" class="cls-3 cc_animation15" cx="4" cy="4" r="4"
								transform="translate(1040 103.245)" />
							<circle id="椭圆_37-2" data-name="椭圆 37" class="cls-3 cc_animation16" cx="4" cy="4" r="4"
								transform="translate(1100 85.245)" />
						<!-- 	<circle id="椭圆_38-2" data-name="椭圆 38" class="cls-3 cc_animation17" cx="4" cy="4" r="4"
								transform="translate(1160 54.245)" /> -->
						</g>
					</g>
				</svg>
				<div class="Course__title">
					<div class="title_icon"></div>
					<div class="title_title1">公司历程</div>
					<div class="title_title2">Course</div>
				</div>
				<a href="/aboutUs?subtab=1" class="Course__more">
					<img src="../../assets/img/Course_more.png" />
					<span class="text">查看完整历程</span>
				</a>
				<div class="Course_title1">
					<div>
						<span class="titleColor">2009.04</span>
					</div>

					<div class="titleTxt">
						<div>亿事达正式注册成立</div>
					</div>
				</div>

				<div class="Course_title2">
					<div>
						<span class="titleColor">2013.12</span>
					</div>

					<div class="titleTxt">
						<div>万家汇 · 新邻里1期盛大开业</div>
					</div>
				</div>

				<div class="Course_title3">
					<div>
						<span class="titleColor">2014.10</span>
					</div>

					<div class="titleTxt">
						<div>雄楚一号 · 百汇井1期盛大开业</div>
					</div>
				</div>

				<div class="Course_title4">
					<div>
						<span class="titleColor">2015.05</span>
					</div>

					<div class="titleTxt">
						<div>阳新大酒店开业</div>
					</div>
				</div>

				<div class="Course_title5">
					<div>
						<span class="titleColor">2017.09</span>
					</div>

					<div class="titleTxt">
						<div>华林广场盛大开业</div>
					</div>
				</div>

				<div class="Course_title6">
					<div>
						<span class="titleColor">2017.12</span>
					</div>

					<div class="titleTxt">
						<div>万家汇 · 新邻里2期盛大开业</div>
					</div>
				</div>

				<div class="Course_title7">
					<div>
						<span class="titleColor">2018.01</span>
					</div>

					<div class="titleTxt">
						<div>华汇广场盛大开业</div>
					</div>
				</div>

				<div class="Course_title8">
					<div>
						<span class="titleColor">2018.10</span>
					</div>

					<div class="titleTxt">
						<div>虹桥荟盛大开业</div>
					</div>
				</div>

				<div class="Course_title9">
					<div>
						<span class="titleColor">2019.03</span>
					</div>

					<div class="titleTxt">
						<div>美好广场盛大开业</div>
					</div>
				</div>

				<div class="Course_title10">
					<div>
						<span class="titleColor">2019.08</span>
					</div>

					<div class="titleTxt">
						<div>雄楚一号 · 百汇井2期盛大开业</div>
					</div>
				</div>
				<div class="Course_title11">
					<div>
						<span class="titleColor">2020.07</span>
					</div>

					<div class="titleTxt">
						<div>亿事达旗下项目全面更换季佳产品线案名</div>
					</div>
				</div>
				<div class="Course_title12">
					<div>
						<span class="titleColor">2020.12</span>
					</div>
					<div class="titleTxt">
						<div>季佳 · 里 | 古田1期盛大开业</div>
					</div>
				</div>
				<div class="Course_title13">
					<div>
						<span class="titleColor">2021.08</span>
					</div>
					<div class="titleTxt">
						<div>季佳 · 荟 | 新华家园盛大开业</div>
					</div>
				</div>
				<div class="Course_title14">
					<div>
						<span class="titleColor">2021.09</span>
					</div>
					<div class="titleTxt">
						<div>季佳 · 荟 | 烽火盛大开业</div>
					</div>
				</div>
				<div class="Course_title15">
					<div>
						<span class="titleColor">2021.10</span>
					</div>
					<div class="titleTxt">
						<div>季佳 · 里 | 汉桥小镇正式开业</div>
					</div>
				</div>
				<div class="Course_title16">
					<div>
						<span class="titleColor">2022.10</span>
					</div>
					<div class="titleTxt">
						<div>季佳 · 里 | 建和开业</div>
					</div>
				</div>
				<div class="Course_title17">
					<div>
						<span class="titleColor">2024.09</span>
					</div>
					<div class="titleTxt">
						<div>季佳PAI正式开业</div>
					</div>
				</div>
				<!-- <div class="Course_title18">
					<div>
						<span class="titleColor">敬请期待</span>
					</div>
					<div class="titleTxt">
						<div>季佳广场 | 汉桥筹备中</div>
					</div>
				</div> -->
			</div>
		</div>

		<div class="Commercial_Projects fade-in">
			<img class="Projects_bgimg" src="../../assets/img/Commercial_Projects@2x.png" alt />
			<div class="Projects_title">Commercial Projects</div>
			<div class="Projects_line">
				<div class="Projects_line_line"></div>
			</div>

			<div class="Projects_title2">商业项目</div>

			<div class="Projects_imgList">
				<!-- <div class="img_item" v-for="(item,index) in Projects" :key="index">
          <img :src="item.img" alt />
          <div class="img_content">
            <div class="img_item_text">
              <div class="item_text_title">季佳广场</div>
              <div class="item_text_title2">丰富城市生活的体验空间</div>
              <div
                class="item_text_title3"
              >季佳广场——项目定位为城市级综合型体验购物中心，体量多为8万方以上，更关注消费者的体验价值，打造对于购物需求外的社交、休闲、娱乐、文化、商务洽谈、亲子家庭的沉浸式体验空间。</div>
              <button type="success" plain class="img_btn">查看更多</button>
            </div>
          </div>
        </div>-->
				<div class="img_item" :class="active==0?'itemActive':''" @mouseover="mouseOver(0)">
					<img src="../../assets/img/projects_item8.png" alt />
					<div class="img_content">
						<div class="img_item_text">
							<div class="item_text_title">季佳 · 荟 | 新华家园</div>
							<div class="item_text_title2">品质新邻里生活的策源地</div>
							<div class="item_text_title3">
								季佳 · 荟 | 新华家园项目位于武汉市汉口城区核心居住版块之一—杨汊湖片区，城市主干道姑嫂树路与红旗渠路交汇处，亿事达集团将以关注生活化家庭消费为核心，以儿童成长需求及亲子家庭需求为出发点，构建集合体验游乐、素质教育、亲子互动、精品购物、优享美食五大功能为一体的一站式家庭生活体验中心。
							</div>

							<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=77" target="_blank">
								<button type="success" plain class="img_btn">查看更多</button>
							</a>
						</div>
					</div>
				</div>
				<div class="img_item" :class="active==1?'itemActive':''" @mouseover="mouseOver(1)">
					<img src="../../assets/img/Clipboard4@2x.jpg" alt />
					<div class="img_content">
						<div class="img_item_text">
							<div class="item_text_title">季佳 · 荟 | 百步亭</div>
							<div class="item_text_title2">品质新邻里生活的策源地</div>
							<div class="item_text_title3">
								季佳 · 荟 | 百步亭位于后湖大道与百步亭花园路交汇处，与地铁21号线百步亭花园路站无缝对接。经营面积4万方，覆盖餐饮美食、精品百货、儿童游乐、教育培训、健康体验等多功能业态。项目以“品质生活·多彩邻里”为口号，营造潮玩艺术与主题特色相结合的跨界体验场景，打造干净、舒适的购物环境，充分满足百步亭居民日常所需。目前，盒马鲜生、海底捞已盛大入驻。
							</div>
							<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=63" target="_blank">
								<button type="success" plain class="img_btn">查看更多</button>
							</a>
						</div>
					</div>
				</div>
				<div class="img_item" :class="active==2?'itemActive':''" @mouseover="mouseOver(2)">
					<img src="../../assets/img/Clipboard3@2x.jpg" alt />
					<div class="img_content">
						<div class="img_item_text">
							<div class="item_text_title">季佳 · 荟丨烽火</div>
							<div class="item_text_title2">品质新邻里生活的策源地</div>
							<div class="item_text_title3">
								季佳 · 荟 | 烽火 地处武汉市洪山区白沙洲大道与江盛路十字路口，以周边地缘客群、家庭客群作为主要目标客群，强调生活、商业的交流与融合，涵盖精品零售、优享美食、休闲娱乐、品质生活、儿童成长五大类业态，致力于为消费者打造一处便捷舒适、悦享生活的社区商业体验中心。
							</div>
							<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=85" target="_blank">
								<button type="success" plain class="img_btn">查看更多</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="Latest_News fade-in">
			<img class="Projects_bgimg Latest_News_img2" src="../../assets/img/Latest_new@2x.png" alt />
			<div class="Projects_title" style="color:#333333">The Latest News</div>
			<div class="Projects_line" style="background-color:#B2B2B2">
				<div class="Projects_line_line" style="background-color:#0044d5;"></div>
			</div>
			<div class="Projects_title2" style="color:#333333">最新资讯</div>

			<div class="Latest_News_img" v-if="CompanyNewsList[0]" @click="tobtn(true,CompanyNewsList[0])">
				<img v-lazy="CompanyNewsList[0].img+'?x-oss-process=image/format,jpg'" alt />
				<div>{{CompanyNewsList[0].summary}}</div>
			</div>

			<div class="Latest_News_right">
				<div class="Latest_News_right_item" v-for="(item,index) in CompanyNewsList" :key="index"
					@click="tobtn(true,item)">
					<div class="right_content_title">{{item.title}}</div>
					<div class="right_time">{{item.issceTime}}</div>
					<div class="right_content">{{item.summary | ellipsis(80)}}</div>
					<div class="right_line"></div>
				</div>
			</div>

			<div class="Latest_News_btn">
				<button @click="tobtn()">查看更多</button>
			</div>
		</div>

		<div class="Partners fade-in">
			<img class="Projects_bgimg" src="../../assets/img/Partners2x.png" alt />
			<div class="Projects_title">Partners</div>
			<div class="Projects_line">
				<div class="Projects_line_line"></div>
			</div>
			<div class="Projects_title2">合作伙伴</div>

			<div class="Partners_items">
				<div class="Partner_item" v-for="(item,index) in Partners" :key="index">
					<div class="Partners_item">
						<!-- <img v-lazy="item.img" alt /> -->
						<el-image :src="item.img+'?x-oss-process=image/format,jpg'" lazy></el-image>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { _debounce } from "@/utils/gloal";
	import Cookies from "js-cookie";
	import {
		getIndexB,
		getJoList
	} from "@/api/api";
	export default {
		name: "index",
		metaInfo: {
			title: "亿事达集团官网", // set a title
			meta: [{
					// set meta
					name: "keyWords",
					content: "亿事达官网,亿事达,季佳,季佳·荟,季佳·里,亿事达集团,"
				},
				{
					name: "description",
					content: "亿事达集团成立于2009年，是武汉本土领先的城市配套商业服务商，现有行业专才500余人。在商业资产运作领域拥有丰富的经验和雄厚的实力，11年时间已经成为一家以核心人才与全能托管为核心价值的专业机构，未来，亿事达将进一步深耕武汉，辐射华中，并逐步走向全国。"
				}
			]
		},
		inject: ["reload"],
		data() {
			return {
				index_benner: [],
				Projects: [],
				Partners: [],
				CompanyNewsList: [],
				fadeInElements: [],
				active: 0
			};
		},
		created() {
			this.getIndexB();
			this.getJoList();
		},
		activated() {
			this.$store.commit("updateTab", 1);
		},
		mounted() {
			// 先获取全部需要过渡效果的元素
			this.$store.commit("updateTab", 1);
			this.fadeInElements = Array.from(
				document.getElementsByClassName("fade-in")
			);
			// 监听鼠标滚动事件
			this.$nextTick(function() {
				window.addEventListener("scroll", this.handleScroll, true);
			});
		},
		filters: {
			ellipsis(value, num) {
				if (value.length > num) {
					return value.substring(0, num) + "...";
				} else {
					return value;
				}
			}
		},
		methods: {
			getIndexB() {
				let body = {
					siteModle: "0",
					site: "0"
				};
				let body2 = {
					siteModle: "0",
					site: "1"
				};
				let body3 = {
					siteModle: "0",
					site: "2"
				};
				getIndexB(body)
					.then(res => {
						this.index_benner = res.data.retData;
					})
					.catch(err => {
						console.log(err);
					});
				getIndexB(body2)
					.then(res => {
						console.log(res.data);

						this.Projects = res.data.retData;
					})
					.catch(err => {
						console.log(err);
					});
				getIndexB(body3)
					.then(res => {
						console.log(res.data);
						this.Partners = res.data.retData;
					})
					.catch(err => {
						console.log(err);
					});
			},
			getJoList() {
				let body = {
					current: 1,
					pageSize: 3,
					type: 0
				};
				getJoList(body)
					.then(res => {
						console.log(res.data);
						this.CompanyNewsList = res.data.retData;
					})
					.catch(err => {
						console.log(err);
					});
			},

			tobtn(isDetails, val) {
				if (isDetails) {
					if (val.url) {
						window.open(val.url, "_blank");
					} else {
						this.$router.push(
							"/CompanyNews?isDetails=" + isDetails + "&id=" + val.id
						);
					}
				} else {
					this.$router.push("/CompanyNews");
				}

				this.reload();
			},
			toLink(link) {
				if(link){
					window.open(link, "_blank");
				}
			},
			// 循环判断是否要触发过渡
			handleScroll(e) {
				this.scrollTop =
					document.documentElement.scrollTop ||
					window.pageYOffset ||
					document.body.scrollTop;

				if (this.scrollTop > 300) {
					this.toTopShow = true;
				} else {
					this.toTopShow = false;
				}
				for (var i = 0; i < this.fadeInElements.length; i++) {
					var elem = this.fadeInElements[i];
					if (this.isElemVisible(elem)) {
						elem.style.cssText = `
                opacity: 1;
                filter: alpha(opacity = 100);
                filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 100);
                -webkit-transform:translateY(0px);
                -moz-transform: translateY(0px);
                -ms-transform: translateY(0px);
                transform: translateY(0px);
                -webkit-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
                -moz-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
                -ms-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
                transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);`;
						this.fadeInElements.splice(i, 1); // 只让它运行一次
					}
				}
			},
			// 判断元素距离窗口的位置
			isElemVisible(el) {
				var rect = el.getBoundingClientRect();
				var elemTop = rect.top; // 200 = buffer
				var elemBottom = rect.bottom;
				return elemTop < window.innerHeight && elemBottom >= 0;
			},
			mouseOver(index) {
				console.log(index)
				this.active = index
			},
			mouseLeave() {

			}
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll, true);
		}
	};
</script>

<style lang="scss">
	$vm_w_base: 1920;
	$vm_h_base: 1080;

	@function vm_w($px) {
		@return ($px / 1920) * 100vw;
	}
	.bannerImg{
		width: 100%;
		height: 100%;
	}
	@keyframes fade-in {
		from {
			transform: translateY(vm_w(30));
			-webkit-transform: translateY(vm_w(30));
			-moz-transform: translateY(vm_w(30));
			-ms-transform: translateY(vm_w(30));
			-o-transform: translateY(vm_w(30));
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		}

		to {
			transform: translateY(0);
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			-o-transform: translateY(0);
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}
	}
	
	@keyframes width_show {
		from {
			width: 0%;
		}

		to {
			width: 100%;
		}
	}

	.com {
		.el-carousel__item h3 {
			color: #475669;
			font-size: vm_w(18);
			opacity: 0.75;
			filter: alpha(opacity=75);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=75);
			margin: 0;
		}

		.carousel {
			width: 100%;
			height: vm_w(640);
		}

		.el-carousel__container {
			height: 100% !important;
		}

		.el-carousel__arrow.el-carousel__arrow--right {
			background-color: rgba(0, 0, 0, 0);
			width: auto;
			height: auto;
		}

		.el-carousel__arrow.el-carousel__arrow--left {
			background-color: rgba(0, 0, 0, 0);
			width: auto;
			height: auto;
		}

		.el-icon-arrow-left {
			font-size: vm_w(62);
		}

		.el-icon-arrow-right {
			font-size: vm_w(62);
		}

		.el-carousel__item:nth-child(2n) {
			background-color: #99a9bf;
		}

		.el-carousel__item:nth-child(2n + 1) {
			background-color: #d3dce6;
		}
		.About-Us_and_Course {
			width: 100%;
			height: vm_w(1042);
			box-sizing: border-box;
		}

		.About-Us {
			max-width: 100%;
			height: vm_w(665);
			position: relative;
		}

		.About-Us_bgimg {
			width: 100%;
			height: 100%;
			top: 0px;
			z-index: 0;
			position: absolute;
		}

		.About-Us_concent {
			position: relative;
			max-width: vm_w(1192);
			height: 100%;
			margin: 0 auto;
			z-index: 10;
		}

		.About-Us_title {
			position: absolute;
			width: vm_w(163);
			margin-top: vm_w(60);
			box-sizing: border-box;
			font-size: vm_w(32);
			font-family: Arial, Arial-Bold;
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
			-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
			-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
			-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
			-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
			animation-fill-mode: forwards;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-ms-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
		}

		.title_icon {
			width: vm_w(4);
			display: inline;
			height: vm_w(72);
			background-color: $chooseColor;
			float: left;
			margin: vm_w(5) 0px;
		}

		.title_title1 {
			margin-left: vm_w(20);
		}

		.title_title2 {
			margin-left: vm_w(20);
			margin-top: vm_w(14);
		}

		.About-Us_text {
			position: absolute;
			margin-top: vm_w(205);
			max-width: vm_w(478);
			font-size: vm_w(14);
			color: #666666;
			line-height: vm_w(24);
			z-index: 11;
			font-family: Source Han Sans CN, Source Han Sans CN-Normal;
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
			-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
			-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
			-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
			-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;

			animation-fill-mode: forwards;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-ms-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
		}

		.About-Us_text_text {
			margin-top: vm_w(35);
		}

		.About-Us_img {
			position: absolute;
			top: vm_w(160);
			right: vm_w(86);
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
			-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
			-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
			-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
			-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
			animation-fill-mode: forwards;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-ms-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
		}

		.About-Us_img img {
			width: vm_w(510);
			z-index: 10;
			position: relative;
		}

		.About-Us_img_icon1 {
			position: absolute;
			z-index: 9;
			top: vm_w(-102);
			right: vm_w(-102);
			width: vm_w(376);
			height: vm_w(376);
			background-color: $chooseColor;
		}

		.About-Us_img_icon2 {
			position: absolute;
			z-index: 9;
			bottom: vm_w(-44);
			left: vm_w(-44);
			width: vm_w(102);
			height: vm_w(102);
			border: solid vm_w(2) $chooseColor;
		}

		.Course {
			width: vm_w(1192);
			height: vm_w(377);
			margin: 0px auto;
			position: relative;
			z-index: 1;
			top: vm_w(-80);
		}

		@keyframes lineMove {
			0% {
				stroke-dasharray: 0, 1305;
				// stroke-dashoffset: 1305;
			}

			50% {
				stroke-dasharray: 1305, 1305;
				opacity: 1;
				filter: alpha(opacity=100);
				filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
			}

			100% {
				stroke-dasharray: 1305, 1305;
				opacity: 1;
				filter: alpha(opacity=100);
				filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
			}
		}

		.Course_bgimg {
			width: vm_w(1192);
			height: 100%;
			z-index: 0;

			.cls-1 {
				opacity: 0.15;
			}

			.cls-2 {
				fill: none;
				stroke: $chooseColor;
				stroke-width: 1;
				opacity: 0;
				filter: alpha(opacity=0);
				filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
				animation: lineMove 3.6s ease-out 0.6s;
				-webkit-animation: lineMove 3.6s ease-out 0.6s;
				-moz-animation: lineMove 3.6s ease-out 0.6s;
				-ms-animation: lineMove 3.6s ease-out 0.6s;
				-o-animation: lineMove 3.6s ease-out 0.6s;

				animation-fill-mode: forwards;
				-webkit-animation-fill-mode: forwards;
				-moz-animation-fill-mode: forwards;
				-ms-animation-fill-mode: forwards;
				-o-animation-fill-mode: forwards;
			}

			.cls-3 {
				fill: $chooseColor;
				-webkit-animation: showIn 3s ease;
				-moz-animation: showIn 3s ease;
				-ms-animation: showIn 3s ease;
				-o-animation: showIn 3s ease;
			}

			.cc_animation0 {
				-webkit-animation-duration: 0.1s;
				-moz-animation-duration: 0.1s;
				-ms-animation-duration: 0.1s;
				-o-animation-duration: 0.1s;
			}

			.cc_animation1 {
				-webkit-animation-duration: 0.3s;
				-moz-animation-duration: 0.3s;
				-ms-animation-duration: 0.3s;
				-o-animation-duration: 0.3s;
			}

			.cc_animation2 {
				-webkit-animation-duration: 0.6s;
				-moz-animation-duration: 0.6s;
				-ms-animation-duration: 0.6s;
				-o-animation-duration: 0.6s;
			}

			.cc_animation3 {
				-webkit-animation-duration: 0.9s;
				-moz-animation-duration: 0.9s;
				-ms-animation-duration: 0.9s;
				-o-animation-duration: 0.9s;
			}

			.cc_animation4 {
				-webkit-animation-duration: 1.2s;
				-moz-animation-duration: 1.2s;
				-ms-animation-duration: 1.2s;
				-o-animation-duration: 1.2s;
			}

			.cc_animation5 {
				-webkit-animation-duration: 1.5s;
				-moz-animation-duration: 1.5s;
				-ms-animation-duration: 1.5s;
				-o-animation-duration: 1.5s;
			}

			.cc_animation6 {
				-webkit-animation-duration: 1.8s;
				-moz-animation-duration: 1.8s;
				-ms-animation-duration: 1.8s;
				-o-animation-duration: 1.8s;
			}

			.cc_animation7 {
				-webkit-animation-duration: 2.1s;
				-moz-animation-duration: 2.1s;
				-ms-animation-duration: 2.1s;
				-o-animation-duration: 2.1s;
			}

			.cc_animation8 {
				-webkit-animation-duration: 2.4s;
				-moz-animation-duration: 2.4s;
				-ms-animation-duration: 2.4s;
				-o-animation-duration: 2.4s;
			}

			.cc_animation9 {
				-webkit-animation-duration: 2.7s;
				-moz-animation-duration: 2.7s;
				-ms-animation-duration: 2.7s;
				-o-animation-duration: 2.7s;
			}

			.cc_animation10 {
				-webkit-animation-duration: 3s;
				-moz-animation-duration: 3s;
				-ms-animation-duration: 3s;
				-o-animation-duration: 3s;
			}

			.cc_animation11 {
				-webkit-animation-duration: 3.1s;
				-moz-animation-duration: 3.1s;
				-ms-animation-duration: 3.1s;
				-o-animation-duration: 3.1s;
			}

			.cc_animation12 {
				-webkit-animation-duration: 3.2s;
				-moz-animation-duration: 3.2s;
				-ms-animation-duration: 3.2s;
				-o-animation-duration: 3.2s;
			}

			.cc_animation13 {
				-webkit-animation-duration: 3.3s;
				-moz-animation-duration: 3.3s;
				-ms-animation-duration: 3.3s;
				-o-animation-duration: 3.3s;
			}

			.cc_animation14 {
				-webkit-animation-duration: 3.4s;
				-moz-animation-duration: 3.4s;
				-ms-animation-duration: 3.4s;
				-o-animation-duration: 3.4s;
			}

			.cc_animation15 {
				-webkit-animation-duration: 3.5s;
				-moz-animation-duration: 3.5s;
				-ms-animation-duration: 3.5s;
				-o-animation-duration: 3.5s;
			}

			.cc_animation16 {
				-webkit-animation-duration: 3.6s;
				-moz-animation-duration: 3.6s;
				-ms-animation-duration: 3.6s;
				-o-animation-duration: 3.6s;
			}
			.cc_animation17 {
				-webkit-animation-duration: 3.7s;
				-moz-animation-duration: 3.7s;
				-ms-animation-duration: 3.7s;
				-o-animation-duration: 3.7s;
			}
		}

		@keyframes showIn {

			0%,
			70% {
				opacity: 0;
				filter: alpha(opacity=0);
				filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			}

			100% {
				opacity: 1;
				filter: alpha(opacity=100);
				filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
			}
		}

		.titleColor {
			color: $chooseColor;
		}

		.titleTxt {
			font-size: vm_w(14);
			color: #666;
		}

		.Course_title1 {
			position: absolute;
			width: vm_w(70);
			line-height: vm_w(24);
			bottom: vm_w(19);
			left: vm_w(-2);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 0.1s;
			-moz-animation-duration: 1s;
			-ms-animation-duration: 1s;
			-o-animation-duration: 1s;
		}

		.Course_title1_title {
			font-size: vm_w(20);
		}

		.Course_title1_title2 {
			font-size: vm_w(14);
		}

		.Course__title,
		.Course__more {
			position: absolute;
			box-sizing: border-box;
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
			-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
			-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
			-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
			-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
			animation-fill-mode: forwards;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-ms-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
		}

		.Course__title {
			top: vm_w(30);
			font-size: vm_w(32);
			font-family: Arial, Arial-Bold;
			font-weight: 700;
		}

		.Course__more {
			right: 0;
			bottom: vm_w(-48);

			img {
				width: vm_w(188);
				height: vm_w(194);
			}

			.text {
				font-size: vm_w(16);
				color: $chooseColor;
				position: absolute;
				display: block;
				-moz-transform: rotate(30deg);
				-webkit-transform: rotate(30deg);
				left: vm_w(27);
				bottom: vm_w(48);
			}
		}

		.Course_title2 {
			position: absolute;
			width: vm_w(120);
			line-height: vm_w(24);
			bottom: vm_w(-48);
			left: vm_w(75);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;

			-webkit-animation-duration: 1.3s;
			-moz-animation-duration: 1.3s;
			-ms-animation-duration: 1.3s;
			-o-animation-duration: 1.3s;
		}

		.Course_title3 {
			position: absolute;
			width: vm_w(135);
			line-height: vm_w(24);
			bottom: vm_w(65);
			left: vm_w(84);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 1.6s;
			-moz-animation-duration: 1.6s;
			-ms-animation-duration: 1.6s;
			-o-animation-duration: 1.6s;
		}

		.Course_title4 {
			position: absolute;
			width: vm_w(70);
			line-height: vm_w(24);
			bottom: vm_w(20);
			left: vm_w(205);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 1.9s;
			-moz-animation-duration: 1.9s;
			-ms-animation-duration: 1.9s;
			-o-animation-duration: 1.9s;
		}

		.Course_title5 {
			position: absolute;
			width: vm_w(70);
			line-height: vm_w(24);
			bottom: vm_w(124);
			left: vm_w(247);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 2.2s;
			-moz-animation-duration: 2.2s;
			-ms-animation-duration: 2.2s;
			-o-animation-duration: 2.2s;
		}

		.Course_title6 {
			position: absolute;
			width: vm_w(120);
			line-height: vm_w(24);
			bottom: vm_w(45);
			left: vm_w(307);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 2.5s;
			-moz-animation-duration: 2.5s;
			-ms-animation-duration: 2.5s;
			-o-animation-duration: 2.5s;
		}

		.Course_title7 {
			position: absolute;
			width: vm_w(70);
			line-height: vm_w(24);
			bottom: vm_w(142);
			left: vm_w(388);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 2.8s;
			-moz-animation-duration: 2.8s;
			-ms-animation-duration: 2.8s;
			-o-animation-duration: 2.8s;
		}

		.Course_title8 {
			position: absolute;
			width: vm_w(70);
			line-height: vm_w(24);
			bottom: vm_w(59);
			left: vm_w(457);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 3.1s;
			-moz-animation-duration: 3.1s;
			-ms-animation-duration: 3.1s;
			-o-animation-duration: 3.1s;
		}

		.Course_title9 {
			position: absolute;
			width: vm_w(70);
			line-height: vm_w(24);
			top: vm_w(140);
			left: vm_w(520);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 3.4s;
			-moz-animation-duration: 3.4s;
			-ms-animation-duration: 3.4s;
			-o-animation-duration: 3.4s;
		}

		.Course_title10 {
			position: absolute;
			width: vm_w(135);
			line-height: vm_w(24);
			bottom: vm_w(106);
			left: vm_w(598);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 3.7s;
			-moz-animation-duration: 3.7s;
			-ms-animation-duration: 3.7s;
			-o-animation-duration: 3.7s;
		}

		.Course_title11 {
			position: absolute;
			width: vm_w(120);
			line-height: vm_w(24);
			top: vm_w(70);
			left: vm_w(645);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 4s;
			-moz-animation-duration: 4s;
			-ms-animation-duration: 4s;
			-o-animation-duration: 4s;
		}

		.Course_title12 {
			position: absolute;
			width: vm_w(120);
			line-height: vm_w(24);
			bottom: vm_w(138);
			left: vm_w(753);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 4.3s;
			-moz-animation-duration: 4.3s;
			-ms-animation-duration: 4.3s;
			-o-animation-duration: 4.3s;
		}

		.Course_title13 {
			position: absolute;
			width: vm_w(120);
			line-height: vm_w(24);
			top: vm_w(80);
			left: vm_w(790);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 4.6s;
			-moz-animation-duration: 4.6s;
			-ms-animation-duration: 4.6s;
			-o-animation-duration: 4.6s;
		}

		.Course_title14 {
			position: absolute;
			width: vm_w(120);
			line-height: vm_w(24);
			bottom: vm_w(150);
			left: vm_w(900);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 4.9s;
			-moz-animation-duration: 4.9s;
			-ms-animation-duration: 4.9s;
			-o-animation-duration: 4.9s;
		}

		.Course_title15 {
			position: absolute;
			width: vm_w(105);
			line-height: vm_w(24);
			top: vm_w(50);
			right: vm_w(160);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 5.2s;
			-moz-animation-duration: 5.2s;
			-ms-animation-duration: 5.2s;
			-o-animation-duration: 5.2s;
		}

		.Course_title16 {
			position: absolute;
			width: vm_w(152);
			line-height: vm_w(24);
			top: vm_w(124);
			left: vm_w(1000);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 5.5s;
			-moz-animation-duration: 5.5s;
			-ms-animation-duration: 5.5s;
			-o-animation-duration: 5.5s;
		}

		.Course_title17 {
			position: absolute;
			width: vm_w(62);
			line-height: vm_w(24);
			top: vm_w(6);
			right:vm_w(70);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 5.8s;
			-moz-animation-duration: 5.8s;
			-ms-animation-duration: 5.8s;
			-o-animation-duration: 5.8s;
		}
		.Course_title18 {
			position: absolute;
			width: vm_w(110);
			line-height: vm_w(24);
			top: vm_w(80);
			right:vm_w(-60);
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			-webkit-animation: showIn 2s ease;
			-moz-animation: showIn 2s ease;
			-ms-animation: showIn 2s ease;
			-o-animation: showIn 2s ease;
			-webkit-animation-duration: 5.8s;
			-moz-animation-duration: 5.8s;
			-ms-animation-duration: 5.8s;
			-o-animation-duration: 5.8s;
		}

		.fade-in {
			width: 100%;
			height: 100%;
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			transform: translateY(30px);
			transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
				transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
			-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
				-webkit-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
			-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
				-moz-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
			-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
				-ms-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
			-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
				-o-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);

			// -webkit-transform: scale(0.8);
			// -moz-transform: scale(0.8);
			// -ms-transform: scale(0.8);
			// -o-transform: scale(0.8);
			box-sizing: border-box;
		}

		.Commercial_Projects {
			width: 100%;
			height: vm_w(1012);
			box-sizing: border-box;
			position: relative;
		}

		.Projects_bgimg {
			width: 100%;
			height: 100%;
			z-index: 0;
			position: absolute;
			top: 0;
			left: 0;
		}

		.Projects_title {
			position: absolute;
			top: vm_w(64);
			color: #ffffff;
			font-size: vm_w(32);
			font-family: Arial, Arial-Bold;
			font-weight: 700;
			z-index: 1;
			width: 100%;
			text-align: center;
		}

		.Projects_line {
			width: vm_w(318);
			height: vm_w(2);
			background-color: #ffffff;
			position: absolute;
			top: vm_w(122);
			left: calc(50% - #{vm_w(159)});
			left: -webkit-calc(50% - #{vm_w(159)});
			left: -moz-calc(50% - #{vm_w(159)});
			left: -ms-calc(50% - #{vm_w(159)});
			left: -o-calc(50% - #{vm_w(159)});
		}

		.Projects_line_line {
			width: vm_w(64);
			height: vm_w(4);
			position: absolute;
			top: vm_w(-1);
			background-color: #01ab94;
			left: calc(50% - #{vm_w(32)});
			left: -webkit-calc(50% - #{vm_w(32)});
			left: -moz-calc(50% - #{vm_w(32)});
			left: -ms-calc(50% - #{vm_w(32)});
			left: -o-calc(50% - #{vm_w(32)});
		}

		.Projects_title2 {
			position: absolute;
			top: vm_w(136);
			color: #ffffff;
			font-size: vm_w(32);
			font-family: Arial, Arial-Bold;
			font-weight: 700;
			z-index: 1;
			width: 100%;
			text-align: center;
		}

		.Projects_imgList {
			min-width: vm_w(1200);
			max-width: vm_w(1740);
			height: vm_w(812);
			position: absolute;
			bottom: 0px;
			z-index: 1;
			left: calc(50% - #{vm_w(600)});
			left: -webkit-calc(50% - #{vm_w(600)});
			left: -moz-calc(50% - #{vm_w(600)});
			left: -ms-calc(50% - #{vm_w(600)});
			left: -o-calc(50% - #{vm_w(600)});
			text-align: center;
		}

		.img_item {
			width: vm_w(306);
			max-width: vm_w(580);
			overflow: hidden;
			height: 100%;
			display: inline-block;
			vertical-align: top;
			transition: all 0.5s;
			-webkit-transition: all 0.5s;
			-moz-transition: all 0.5s;
			-ms-transition: all 0.5s;
			-o-transition: all 0.5s;
			text-align: center;
			position: relative;
		}

		// .img_item:hover {
		//   overflow: auto;
		//   width: vm_w(580);
		//   transition: all 0.5s;
		//   -webkit-transition: all 0.5s;
		//   -moz-transition: all 0.5s;
		//   -ms-transition: all 0.5s;
		//   -o-transition: all 0.5s;
		//   overflow-x: hidden;
		// }
		// .img_item:hover .img_content {
		//   opacity: 1;
		//   filter: alpha(opacity = 100);
		//   filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 100);
		// }
		.itemActive {
			overflow: auto;
			width: vm_w(580);
			transition: all 0.5s;
			-webkit-transition: all 0.5s;
			-moz-transition: all 0.5s;
			-ms-transition: all 0.5s;
			-o-transition: all 0.5s;
			overflow-x: hidden;
		}

		.itemActive .img_content {
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}

		.img_item img {
			min-width: vm_w(306);
			max-width: vm_w(580);
			height: 100%;
			overflow: hidden;
			object-fit: cover;
		}

		.img_item_text {
			width: vm_w(378);
			font-size: vm_w(14);
			font-family: Source Han Sans CN, Source Han Sans CN-Normal;
			color: #ffffff;
			position: absolute;
			top: 50%;
			left: calc(50% - #{vm_w(189)});
			left: -webkit-calc(50% - #{vm_w(189)});
			left: -moz-calc(50% - #{vm_w(189)});
			left: -ms-calc(50% - #{vm_w(189)});
			left: -o-calc(50% - #{vm_w(189)});
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
		}

		.img_content {
			width: 100%;
			height: 100%;
			background-color: rgba(1, 171, 148, 0.65);
			position: absolute;
			top: 0;
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		}

		.item_text_title {
			font-size: vm_w(24);
			font-family: Source Han Sans CN, Source Han Sans CN-Bold;
			font-weight: 700;
			margin-bottom: vm_w(24);
		}

		.item_text_title2 {
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			margin-bottom: vm_w(14);
		}

		.item_text_title3 {
			font-size: vm_w(14);
			font-family: Source Han Sans CN, Source Han Sans CN-Normal;
			text-align: left;
			line-height: vm_w(24);
			margin-bottom: vm_w(14);
		}

		.img_btn {
			width: vm_w(172);
			height: vm_w(40);
			background: #dbfbf7;
			border: vm_w(1) solid #01ab94;
			border-radius: 4px;
			color: #01ab94;
			cursor: pointer;
		}

		.Latest_News {
			width: 100%;
			height: vm_w(751);
			position: relative;
			text-align: center;
		}

		.Latest_News_img {
			top: vm_w(212);
			width: vm_w(580);
			height: vm_w(414);
			left: vm_w(360);
			cursor: pointer;
			display: inline-block;
			position: absolute;

			& div {
				width: 100%;
				position: absolute;
				line-height: vm_w(32);
				height: vm_w(32);
				bottom: 0;
				background-color: rgba(0, 0, 0, 0.5);
				color: #ffffff;
				font-size: vm_w(18);
				font-weight: 400;
				font-family: Source Han Sans CN, Source Han Sans CN-Regular;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				box-sizing: border-box;
				animation: marquee 50s linear infinite;
				-webkit-animation: marquee 50s linear infinite;
				-moz-animation: marquee 50s linear infinite;
				-ms-animation: marquee 50s linear infinite;
				-o-animation: marquee 50s linear infinite;

				&:hover {
					animation-play-state: paused;
					-webkit-animation-play-state: paused;
					-moz-animation-play-state: paused;
					-ms-animation-play-state: paused;
					-o-animation-play-state: paused;
				}

				/* Make it move */
				@keyframes marquee {
					0% {
						text-indent: 27.5em;
					}

					100% {
						text-indent: -105em;
					}
				}
			}
		}

		.Latest_News_img2 {
			height: vm_w(698);
		}

		.Latest_News_img img {
			width: 100%;
			height: 100%;
		}

		.Latest_News_right {
			display: inline-block;
			position: absolute;
			width: vm_w(580);
			left: vm_w(972);
			top: vm_w(215);
			vertical-align: top;
			text-align: left;
			z-index: 100;
		}

		.Latest_News_right_item {
			margin-top: vm_w(24);
			cursor: pointer;
			z-index: 101;
		}

		.Latest_News_right_item:hover .right_content_title {
			color: $chooseColor;
		}

		.Latest_News_right_item:hover .right_time {
			color: $chooseColor;
		}

		.Latest_News_right_item:hover .right_content {
			color: $chooseColor;
		}

		.latest_right_content {}

		.latest_right_content_style {
			margin-top: vm_w(20);
		}

		.right_content_title {
			font-size: vm_w(18);
			font-family: Source Han Sans CN, Source Han Sans CN-Medium;
			font-weight: bold;
			color: #333333;
			box-sizing: border-box;
			width: vm_w(401);
			white-space: nowrap;
			overflow: hidden;
			display: inline-block;
			text-overflow: ellipsis;
		}

		.right_time {
			font-size: vm_w(14);
			font-family: Source Han Sans CN, Source Han Sans CN-Normal;
			text-align: left;
			color: #999999;
			line-height: vm_w(22);
			vertical-align: top;
			float: right;
			display: inline-block;
		}

		.right_content {
			font-size: vm_w(14);
			font-family: Source Han Sans CN, Source Han Sans CN-Normal;
			color: #666666;
			line-height: vm_w(24);
			margin-top: vm_w(10);
			overflow: hidden;
			height: vm_w(50);
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}

		.right_line {
			width: vm_w(204);
			height: vm_w(8);
			background: #0d52dc;
			margin-top: vm_w(12);
		}

		.Latest_News_btn {
			position: absolute;
			top: vm_w(646);
			width: 100%;
			text-align: center;
		}

		.Latest_News_btn button {
			width: vm_w(236);
			height: vm_w(40);
			background: $chooseColor;
			border: vm_w(1) solid $chooseColor;
			z-index: 999;
			font-size: vm_w(16);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			border-radius: 4px;
			margin-top: vm_w(32);
			color: #fff;
			cursor: pointer;
		}

		.Partners {
			width: 100%;
			height: vm_w(526);
			position: relative;
			box-sizing: border-box;
		}

		.Partners_items {
			width: vm_w(1208);
			height: vm_w(327);
			position: absolute;
			left: calc(50% - #{vm_w(600)});
			left: -webkit-calc(50% - #{vm_w(600)});
			left: -moz-calc(50% - #{vm_w(600)});
			left: -ms-calc(50% - #{vm_w(600)});
			left: -o-calc(50% - #{vm_w(600)});
			padding-top: vm_w(181);
			display: table;
			overflow: hidden;
		}

		.Partner_item {
			margin-left: vm_w(32);
			display: inline-block;
		}

		.Partners_item {
			width: vm_w(208);
			height: vm_w(70);
			z-index: 5;
			margin-top: vm_w(34);
		}

		.Partners_item img {
			width: 100%;
			height: 100%;
		}

		.toTop {
			position: fixed;
			bottom: vm_w(177);
			right: vm_w(164);
			width: vm_w(48);
			height: vm_w(48);
			text-align: center;
			line-height: vm_w(48);
			background: #e6e6e6;
			border: vm_w(1) solid #b4b4b4;
			border-radius: 4px;
			cursor: pointer;
			z-index: 100;

			font-size: vm_w(18);
			color: #666666;
		}
	}

	// .cls-4 {
	// 	 fill: #124db3;
	// }
</style>
